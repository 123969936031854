<template>
	<e-select
		track-by="articles_id"
		label="articles_label_format"
		v-model="searchValue"
		:options="options"
		:placeholder="$t('global.ecrire_pour_rechercher')"
		:loading="isLoading"
		:sortable="false"
		:internal-search="false"
		@search-change="onSearch"
		@select="searchArticles"
		ref="input"
	>
		<template slot="noOptions">{{ $t('global.write_to_search') }}</template>
		<template slot="noResult">{{ $t('global.no_result') }}</template>
	</e-select>
</template>

<script>
import _debounce from 'lodash/debounce'
import Navigation from '@/mixins/Navigation'
import Accounting from '@/mixins/Accounting'
import ArticleTransformer from '@/assets/js/dexie/transformers/ArticleTransformer'

export default {
	name: 'SearchArticles',
	mixins: [Navigation, Accounting],
	props: ['preselected', 'entity_id'],
    data() {
        return {
			accounting_accounts_ids: [],
			options: [],
			searchValue: null,
			isLoading: false,
			debouncedSearch: _debounce(this.search, 300),
			preselected_local: null
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			if(this.preselected) {
				this.preselected_local = this.preselected
				this.preselected_local.articles_label_format = this.preselected.articles_label + ' - (' + this.preselected.articles_code +')'
				this.options = [this.preselected_local]
				this.searchValue = this.preselected_local
			}

			if(this.entity_id) {
				this.isLoading = true
				this.loadAccountingPlanByEntity(this.entity_id).then(accounting_plan => {
					if(accounting_plan) {
						this.loadAccountingAccount(accounting_plan.accountingplan_id)
					}
				})
			}
		},
		onSearch(search_val) {
			this.debouncedSearch(search_val)
		},
		async search(search_val) {
			this.isLoading = true

			if(search_val == '') {
				this.isLoading = false
				return false
			}

			let articles

			if(this.$storage.db.articles && this.$storage.db.accounting_account) {
				articles = await this.$storage.db.t('articles').then(table => {
					return table
						.where('articles_accountingaccount').anyOf(this.accounting_accounts_ids)
						.and(articles => articles.articles_inactive == 0)
				})
				.then(col => {
					return col.filter(elem => {
						var regex = new RegExp(search_val, 'gi');
						return regex.test(elem.articles_label +' '+ elem.articles_code)
					})
				})
				.then(col => {
					return col.transform(new ArticleTransformer())
				})
			}

			
			this.options = articles.sortBy('articles_label')
			
			this.isLoading = false
		},
		onFocus() {
			this.$refs.input.activate()
			this.$refs.input.$refs.search.click()
		},
		async searchArticles(option) {
			this.searchValue = option.articles_rs
			this.$emit("update:articles_selected", option)
		},
		async loadAccountingAccount(accountingplan_id){
			const accounting_accounts = await this.loadAccountingAccountsFromLocal(accountingplan_id)
	
			if(accounting_accounts != undefined) {
				this.accounting_accounts_ids = accounting_accounts.map(elem => {
					return elem.accountingaccount_id
				})
			}
			this.isLoading = false

		}
	},
	watch:{
		entity_id(val){
			if(val){
				this.isLoading = true
				this.loadAccountingPlanByEntity(this.entity_id).then(accounting_plan => {
					if(accounting_plan) {
						this.loadAccountingAccount(accounting_plan.accountingplan_id)
					}
				})
			}
		},
		preselected(val){
			if(val != undefined)
			{
				this.preselected_local = val
				this.preselected_local.articles_label_format = val.articles_label + ' - (' + val.articles_code +')'
				this.options = [this.preselected_local]
				this.searchValue = this.preselected_local
			}
		}
	}
}
</script>
